<template>
  <section class="page page-tariffs">
    <div class="wrapper">
      <div class="breacrumbs">
        <router-link to="/">Главная</router-link>
        <span class="separator">/</span>
        <span class="current">Тариф junior</span>
      </div>
      <div class="page-title">
        <h1>Общаяя информация по тарифу</h1>
      </div>
      <div>
        <h2>
          Для кого этот тариф?
        </h2>
        <p>
          Данный тариф предназначен для людей не работающих до этого с языком
          Java, а так же для людей кто хочет погрузиться глубже в материал для
          перехода на следующий уровень.
        </p>

        <h2>
          Как будет проходить обучение?
        </h2>
        <p>
          В рамках данного тарифа предоставляется полный доступ к всем урокам
          платформы, проходя которые студент решает практические задачи и
          движется по дороге. Модули включают в себя индивидуальные
          экзаменационные онлайн сессии, в которых ментор задаёт вопросы
          согласно пройденного материала, после чего формируется фитбек и
          студент идет дальше. Каждый студент состоит в группе из 5-10 человек.
          Студент участвующий в группе может задавать интересующие его вопросы,
          ответ на который может быть дан ментором в течение суток. Как правило,
          ответ от ментора будет включать ссылку на дополнительный источник
          материала, а так же персональный комментарий.
        </p>
        <h2>
          Возврат денежных средств.
        </h2>
        <p>
          Каждый студент вправе вернуть деньги, которая высчитывается по
          формуле: полная стоимость курса - 7 процентов. Запрос на возврат
          денежных средств может быть получен не позднее чем 14 дней с момента
          оплаты.
        </p>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "TariffsJunior"
};
</script>

<style></style>
